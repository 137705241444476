import { Row, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useCart, useKillerQuestions, useLocation } from '@/services'
import { theme } from '@/theme'
import { areLoading, themeColor } from '@/utils'
import { Popover, PopoverContent, PopoverTrigger } from '@surein/ui'
import { Info } from 'lucide-react'
import { keys, omit, path, pluck } from 'ramda'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { CheckoutStepKeys, checkoutSteps } from './steps'

const makeCheckoutSteps = ({
  poaSigned,
  killerQuestions,
  messages,
  tooltips,
}) => {
  const keysToOmit = []

  if (poaSigned) keysToOmit.push(CheckoutStepKeys.poa)

  if (!killerQuestions?.length)
    keysToOmit.push(CheckoutStepKeys.killerQuestions)

  return keys(omit(keysToOmit, checkoutSteps)).map((key) => ({
    key,
    Component: checkoutSteps[key],
    title: messages.titles[key],
    subtitle: messages.subtitles[key],
    tooltip: tooltips[key],
  }))
}

const useMessages = () => {
  const { t } = useTranslation()

  return {
    titles: {
      payment: t('checkout.titles.payment'),
      killerQuestions: t('checkout.titles.killerQuestions'),
      poa: t('checkout.titles.poa'),
      overview: t('checkout.titles.overview'),
      success: t('checkout.titles.success'),
    },
    subtitles: {
      poa: t('checkout.subtitles.poa'),
      overview: t('checkout.subtitles.overview'),
      success: t('checkout.subtitles.success'),
    },
  }
}

const PopoverExplanation = styled(Row)`
  gap: 8px;

  > div:first-child {
    display: flex;
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 50%;
    background: ${themeColor(ThemeColor.info3)};
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    color: ${themeColor(ThemeColor.info1)};
  }
`

const SignPoaTooltip = () => {
  const { t } = useTranslation()
  const popoverExplanationPoints = t('signPoaPopoverExplanations', {
    returnObjects: true,
  })

  return (
    <Popover>
      <PopoverTrigger>
        <Row alignItems="center" gap="4px">
          <Info color={theme.color[ThemeColor.b50]} size="16px" />
          <Typography bold color={ThemeColor.b50} underline variant="badgeText">
            {t('whatIsThis')}
          </Typography>
        </Row>
      </PopoverTrigger>
      <PopoverContent>
        {Object.entries(popoverExplanationPoints).map(([_, point], index) => (
          <PopoverExplanation>
            <div>{index + 1}</div>
            <Typography key={point} variant="badgeText">
              {point}
            </Typography>
          </PopoverExplanation>
        ))}
      </PopoverContent>
    </Popover>
  )
}

const tooltips = {
  [CheckoutStepKeys.poa]: <SignPoaTooltip />,
}

export const useCheckoutSteps = (data = []) => {
  const messages = useMessages()
  const { locationId } = useParams()
  const locationResponse = useLocation(locationId)
  const { isLoading } = useCart()
  const categories = pluck('categoryId', data)
  const killerQuestionsResponse = useKillerQuestions(categories, {
    enabled: !!categories.length,
  })

  return {
    isLoading: areLoading([
      locationResponse,
      killerQuestionsResponse,
      isLoading,
    ]),
    steps: makeCheckoutSteps({
      poaSigned: path(['data', 'company', 'poaSigned'], locationResponse),
      killerQuestions: killerQuestionsResponse.data?.data,
      messages,
      tooltips,
    }),
    companyId: locationResponse.data?.companyId,
    killerQuestions: killerQuestionsResponse.data?.data || [],
  }
}
