import { AssessmentStepKeys } from '@/enums'
import { makeBusinessTypeSubmitValue } from '../../helpers'
import { ConditionalOfferAssessment } from './BasePage'

const liabilityAssessmentSteps = [
  AssessmentStepKeys.businessType,
  AssessmentStepKeys.companyFoundedDateLead,
  AssessmentStepKeys.approxRevenue,
  AssessmentStepKeys.employeesCount,
  AssessmentStepKeys.approxLaborCosts,
]

const makeSubmitValues = () => ({
  approxRevenue,
  approxLaborCosts,
  businessType,
  companyFoundedDateLead,
  ...steps
}) => ({
  ...steps,
  lastYearFinances: { ...approxLaborCosts, ...approxRevenue },
  businessType: makeBusinessTypeSubmitValue(businessType),
  companyFoundedDate: companyFoundedDateLead,
})

const makeStepProps = () => (messages) => ({
  [AssessmentStepKeys.approxRevenue]: {
    helperText: messages.helperText,
  },
  [AssessmentStepKeys.approxLaborCosts]: {
    helperText: messages.helperText,
  },
})

export const LiabilityConditionalOffer = () => (
  <ConditionalOfferAssessment
    categoryId="LI"
    makeStepProps={makeStepProps()}
    stepKeys={liabilityAssessmentSteps}
    transformSubmitValues={makeSubmitValues()}
  />
)
