import { AssessmentStepKeys } from '@/enums'
import { useFormContext } from '@/providers'
import { useSaveAssessment } from '@/services'
import {
  getLocalStorageItem,
  makeContext,
  mixpanelTrack,
  setLocalStorageItem,
} from '@/utils'
import {
  concat,
  flip,
  identity,
  isEmpty,
  keys,
  map,
  mergeDeepLeft,
  pick,
  pipe,
  uniq,
} from 'ramda'
import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useParams, useSearchParams } from 'react-router-dom'

export const useSaveAssessmentStep = (
  step,
  modifier = identity,
  isYearlyAssessment,
) => {
  const { locationId } = useParams()
  const queryClient = useQueryClient()
  const { customValidate, values } = useFormContext()
  const { mutateAsync: saveAssessmentMutation, isLoading } = useSaveAssessment(
    locationId,
    isYearlyAssessment,
  )

  const save = useCallback(
    () =>
      new Promise((resolve, reject) => {
        customValidate({
          onSuccess: () => {
            if (!locationId) {
              mixpanelTrack(step)
              resolve()
            } else {
              saveAssessmentMutation({ [step]: modifier(values) })
                .then(() => {
                  mixpanelTrack(step)
                  if (step === AssessmentStepKeys.userProfile) {
                    queryClient.invalidateQueries('profile')
                  }
                  if (
                    step === AssessmentStepKeys.companyDetails ||
                    step === AssessmentStepKeys.powerOfAttorney
                  ) {
                    queryClient.invalidateQueries('companies')
                  }
                  resolve()
                })
                .catch(() => reject())
            }
          },
          onFail: reject,
        })
      }),
    [values],
  )

  return { save, isLoading }
}

// eslint-disable-next-line import/no-unused-modules
export const [AssessmentValuesProvider, useAssessmentValues] = makeContext()

export const makeSteps = (stepsObj) =>
  pipe(
    flip(pick)(stepsObj),
    keys,
    map((key) => ({ key, ...stepsObj[key] })),
  )

export const makeStepsWithBreadcrumbs = (stepsObj, stepKeys) =>
  stepKeys.map((step) => mergeDeepLeft(step, stepsObj[step.key]))

export const makeBusinessTypeSubmitValue = ({
  businessType,
  locationType,
  locationSubtypes = [],
  locationTypeSpecification,
}) => ({
  businessType,
  locationType: businessType === 'other' ? 'other' : locationType,
  locationSubtypes,
  locationTypeSpecification,
})

export const makeBusinessTypeInitialValue = ({
  locationType,
  locationSubtypes = [],
  ...props
}) => ({
  locationType,
  ...props,
  locationSubtypes:
    locationType === 'other' || isEmpty(locationType)
      ? locationSubtypes
      : uniq([locationType, ...locationSubtypes]),
})

const makeStorageKey = flip(concat)

export const makeStoredCurrentStepKey = makeStorageKey('-current-step')
export const makeStoredAssessmentValuesKey = makeStorageKey('-values')
const makeStoredConfirmationIdKey = makeStorageKey('-confirmation-id')

export const getStoredAssessmentValues = pipe(
  makeStoredAssessmentValuesKey,
  getLocalStorageItem,
)

export const getStoredConfirmationId = pipe(
  makeStoredConfirmationIdKey,
  getLocalStorageItem,
)

export const getStoredCurrentStep = pipe(
  makeStoredCurrentStepKey,
  getLocalStorageItem,
)

export const setStoredAssessmentValues = (categoryId, value) => {
  setLocalStorageItem(makeStoredAssessmentValuesKey(categoryId), value)
}

export const setStoredConfirmationId = (categoryId, value) => {
  setLocalStorageItem(makeStoredConfirmationIdKey(categoryId), value)
}

export const setStoredCurrentStep = (categoryId, value) => {
  setLocalStorageItem(makeStoredCurrentStepKey(categoryId), value)
}

// This function will be deleted after the release of the new version
// of the tracking when funnel product is used instead
export const useForwardFunnelSource = () => {
  const [searchParams] = useSearchParams()
  const source = searchParams.get('funnel_source')

  return source ? { funnel_source: source } : {}
}

export const useForwardFunnelProduct = () => {
  const [searchParams] = useSearchParams()
  const product = searchParams.get('funnel_product')

  return product ? { funnel_product: product } : {}
}

export const useStepperWarning = (value, debounceTime = 1000) => {
  const [shouldShowWarning, setShouldShowWarning] = useState(false)
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, debounceTime)

    return () => {
      clearTimeout(handler)
    }
  }, [value, debounceTime])

  useEffect(() => {
    if (debouncedValue && debouncedValue < 9000) {
      setShouldShowWarning(true)
    } else {
      setShouldShowWarning(false)
    }
  }, [debouncedValue])

  return {
    shouldShowWarning,
  }
}
