import {
  ErrorBoundary,
  Header,
  Loader,
  PageLayout,
  SideBar,
} from '@/components'
import { Breakpoint } from '@/enums'
import {
  IGNORED_ANALYTICS_PATHS,
  usePageView,
  useSegment,
} from '@/modules/analytics'
import { AccessProvider, useAuth } from '@/providers'
import { LanguageIndicator } from '@/routes/LanguageIndicator'
import { useCarriers, useCart, useCompanies, useUserProfile } from '@/services'
import {
  checkIsInPartnerUrl,
  initGTM,
  initHotjar,
  isAdmin,
  isVerified,
  noop,
  useIsInPath,
  useIsMobile,
} from '@/utils'
import { useMarketingCookies } from '@/utils/hooks/useMarketingCookies'
import * as Sentry from '@sentry/react'
import { any, map, prop } from 'ramda'
import { useEffect } from 'react'
import {
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
} from 'react-router-dom'
import { config } from '../config'
import { PreventMobileZoom } from './PreventMobileZoom'
import { routes, simpleHeaderRoutes, transformRoutes } from './routes'
import { ScrollToTop } from './ScrollToTop'
import { UnauthorizedGuard } from './UnauthorizedGuard'

const makeCarrierOptions = map(({ name, carrierId, logoUrl }) => ({
  text: name,
  value: carrierId,
  icon: [config.cdn, logoUrl].join('/'),
}))

export const hasProtectedAccess = (userCompanies = []) =>
  userCompanies.some(
    (company) => company?.userType?.MD || company?.userType?.owner,
  ) || false

const hasHeadquarters = (data = []) => any(prop('isHeadquarters'), data)

const isInPartnerRecommendation = (pathname) =>
  checkIsInPartnerUrl() && pathname.includes('recommendation')

const findPartnerSource = (companies = []) => {
  return (
    companies.find((company) => company.partnerSource !== null)
      ?.partnerSource || null
  )
}

initGTM()
initHotjar()

const AppSkeleton = () => {
  const { width } = useIsMobile(Breakpoint.tab)
  const { pathname } = useLocation()
  const { isAuthenticated, user } = useAuth()
  const shouldFetch = Boolean(
    isAuthenticated && isVerified(user) && !isAdmin(user),
  )
  const { marketingPid, marketingMid } = useMarketingCookies()

  const analytics = useSegment()

  const userProfileResponse = useUserProfile({
    enabled: shouldFetch,
  })

  const companiesResponse = useCompanies({
    enabled: shouldFetch,
  })

  useEffect(() => {
    if (userProfileResponse.data?.userId) {
      analytics.identify(userProfileResponse.data.userId, {
        email: userProfileResponse.data.email,
        first_name: userProfileResponse.data.name,
        last_name: userProfileResponse.data.surname,
        partner_source: findPartnerSource(companiesResponse?.data),
      })
    }
  }, [userProfileResponse.data, companiesResponse.data])

  const carriersResponse = useCarriers({
    enabled: shouldFetch,
    select: makeCarrierOptions,
  })

  const cartResponse = useCart({
    enabled: shouldFetch,
  })

  const isSimpleHeader = useIsInPath(...simpleHeaderRoutes)

  usePageView(IGNORED_ANALYTICS_PATHS, routes)

  if (
    userProfileResponse.isLoading ||
    companiesResponse.isLoading ||
    cartResponse.isLoading
  )
    return <Loader />

  return (
    <ErrorBoundary>
      <div>
        <AccessProvider>
          <PreventMobileZoom />
          <ScrollToTop />
          <UnauthorizedGuard />
          <Header
            border={
              pathname.includes('checkout') ||
              pathname.includes('ssf/offer/') ||
              pathname.includes('cart') ||
              pathname.includes('order-confirmation') ||
              pathname.includes('recommendation')
            }
            cart={cartResponse.data || {}}
            companies={companiesResponse.data || []}
            hasHeadquarters={hasHeadquarters(companiesResponse.data)}
            hidden={
              pathname.includes('assessment') ||
              pathname.includes('funnel') ||
              // TODO: change to a better determinator, good for now
              pathname.includes('qonto')
            }
            simple={isSimpleHeader}
            userCompanies={userProfileResponse.data?.companies || []}
            onLogoClick={isInPartnerRecommendation(pathname) ? noop : undefined}
          />
          {shouldFetch && <LanguageIndicator data={userProfileResponse.data} />}
          <PageLayout
            dark={
              pathname.includes('recommendation') ||
              pathname.includes('checkout')
            }
            simple={pathname.includes('assessment') || isSimpleHeader}
          >
            <SideBar
              cart={cartResponse.data || {}}
              companies={companiesResponse.data || []}
              // Fixme: Expensive, this needs to come from user model
              hasHeadquarters={hasHeadquarters(companiesResponse.data)}
              hidden={
                pathname.includes('assessment') ||
                pathname.includes('checkout') ||
                pathname.includes('ssf/offer/') ||
                pathname.includes('order-confirmation') ||
                pathname.includes('recommendation') ||
                pathname.includes('integrations-redirect') ||
                isSimpleHeader ||
                width <= 655
              }
              userCompanies={userProfileResponse.data?.companies || []}
            />
            <Outlet
              context={{
                user: userProfileResponse.data,
                companies: companiesResponse.data,
                carriers: carriersResponse.data || [],
                cart: cartResponse.data || {},
                marketingPid: marketingPid || null,
                marketingMid: marketingMid || null,
                hasProtectedAccess: hasProtectedAccess(
                  userProfileResponse.data?.companies,
                ),
                hasHeadquarters: hasHeadquarters(companiesResponse.data),
              }}
            />
          </PageLayout>
        </AccessProvider>
      </div>
    </ErrorBoundary>
  )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
)

export const router = sentryCreateBrowserRouter([
  {
    element: <AppSkeleton />,
    children: [
      {
        path: '/',
        children: transformRoutes(routes),
      },
      {
        path: '/en',
        children: transformRoutes(routes),
      },
      {
        path: '/de',
        children: transformRoutes(routes),
      },
      { path: '*', element: <Navigate to="/" /> },
    ],
  },
])
