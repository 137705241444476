import { useFormContext } from '@/providers'
import { find, path, pipe, prop, propEq } from 'ramda'

export const isMd = (companyId, user) =>
  pipe(
    prop('companies'),
    find(propEq('companyId', companyId)),
    path(['userType', 'MD']),
  )(user)

export const useValidateCheckoutStep = (onNext, fields) => {
  const { customValidate } = useFormContext()
  const handleNext = () => {
    new Promise((resolve, reject) => {
      customValidate({
        fields,
        onSuccess: resolve,
        onError: reject,
      })
    }).then(onNext)
  }

  return handleNext
}
