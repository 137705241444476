import { Loader } from '@/components'
import { useAuth } from '@/providers'
import { useSelfAssessment } from '@/services'
import {
  getSessionStorageItem,
  isAdmin,
  isVerified,
  makeLocationPathFromCompanies,
  makeQueryString,
  safeObject,
  shouldRedirectToDashboard,
} from '@/utils'
import { Navigate } from 'react-router-dom'
import { Redirect } from './Redirect'
import { paths } from './paths'

const makeRedirectPath = (data) => {
  if (data.onboarded === false) {
    const company = data.companies[0]
    const location = company.locations[0]

    return paths.assessmentWithKey(location)
  }

  const companies = data.companies

  if (shouldRedirectToDashboard(companies)) {
    return paths.dashboard
  }

  return makeLocationPathFromCompanies(paths)(companies)
}

export const AuthRedirect = () => {
  const { user, isLoading: isAuthLoading } = useAuth()
  const { data, isLoading } = useSelfAssessment({
    enabled: !isAuthLoading && isVerified(user) && !isAdmin(user),
  })
  // Todo - check if this is correct, why are we using sessionStorage here?
  const searchParams = getSessionStorageItem('searchParams')

  if (isLoading) return <Loader />

  if (['/', '/en', '/de', '/en/', '/de/'].includes(window.location.pathname))
    return (
      <Redirect
        to={`${makeRedirectPath(data)}${makeQueryString(
          safeObject(searchParams),
        )}`}
      />
    )

  return (
    <Navigate
      to={`${window.location.pathname}${makeQueryString(
        safeObject(searchParams),
      )}`}
    />
  )
}
