import { Loader } from '@/components'
import { CheckoutBase } from '@/modules/checkout/components/CheckoutBase'
import { FormProvider } from '@/providers'
import { Redirect } from '@/routes/Redirect'
import { paths } from '@/routes/paths'
import {
  checkIsInPartnerUrl,
  useModal,
  useStateObject,
  useSteps,
} from '@/utils'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { SuccessPage } from '../SelfAssessmentPage/public/SuccessPage'
import { ThankYouPage } from '../SelfAssessmentPage/public/ThankYouPage'
import { useCheckoutSteps } from './helpers'
import { CheckoutStepKeys } from './steps'
import { makeValidationSchema } from './validationSchema'

export const CheckoutPageContent = ({ steps, killerQuestions, companyId }) => {
  const { locationId } = useParams()
  const { cart, user } = useOutletContext()
  const data = useMemo(() => cart[locationId] || [], [cart])
  const isInPartnerUrl = checkIsInPartnerUrl()

  const { t } = useTranslation()
  const validations = t('validations', { returnObjects: true })

  const stepState = useSteps({
    initialStep: CheckoutStepKeys.payment,
    steps,
    keyPropName: 'key',
  })
  const contactState = useModal()
  const killerQuestionsState = useModal()

  const [buttonState, setButtonState] = useStateObject({
    onClick: stepState.next,
  })

  const handleCloseKillerQuestions = () => {
    killerQuestionsState.close()
    stepState.back()
  }

  const successState = useModal()

  const { Component } = useMemo(() => stepState.data, [stepState.data?.key])

  if (contactState.isOpen) return <SuccessPage />

  if (successState.isOpen)
    return <ThankYouPage isInPartnerUrl={isInPartnerUrl} />

  return (
    <CheckoutBase
      buttonState={buttonState}
      companyId={companyId}
      data={data}
      initialStepKey={CheckoutStepKeys.payment}
      isPublic={false}
      killerQuestionsState={killerQuestionsState}
      locationId={locationId}
      steps={steps}
      stepState={stepState}
    >
      <FormProvider
        initialValues={{
          fullname: user.fullname,
          birthdate: user.birthdate ? Date.parse(user.birthdate) : null,
        }}
        validationSchema={makeValidationSchema(validations)[stepState.step]}
      >
        <Component
          closeKillerQuestions={handleCloseKillerQuestions}
          companyId={companyId}
          goTo={stepState.goTo}
          killerQuestions={killerQuestions}
          onNext={stepState.next}
          openContact={contactState.open}
          openKillerQuestions={killerQuestionsState.open}
          openSuccess={successState.open}
          setButtonState={setButtonState}
        />
      </FormProvider>
    </CheckoutBase>
  )
}

CheckoutPageContent.propTypes = {
  companyId: PropTypes.string,
  killerQuestions: PropTypes.arrayOf(PropTypes.string),
  steps: PropTypes.arrayOf(PropTypes.string),
}

export const CheckoutPage = () => {
  const { locationId } = useParams()
  const { cart } = useOutletContext()
  const data = cart[locationId]

  const initialCount = data?.length

  const { isLoading, steps, killerQuestions, companyId } = useCheckoutSteps(
    data,
  )

  if (isLoading) return <Loader />

  if (data && !initialCount)
    return <Redirect to={paths.viewCartWithKey(locationId)} />

  return (
    <CheckoutPageContent
      companyId={companyId}
      killerQuestions={killerQuestions}
      steps={steps}
    />
  )
}
