import { Loader } from '@/components'
import { useAssessment, useRecommendation, useUserProfile } from '@/services'
import { useParams } from 'react-router-dom'
import { OrganicRecommendation } from './OrganicRecommendation'
import { PartnerRecommendation } from './PartnerRecommendation'

export const RecommendationPage = () => {
  const { recommendationId } = useParams()

  if (!recommendationId) {
    throw new Error('Recommendation ID is required')
  }

  const { data, isLoading } = useRecommendation(recommendationId)
  const {
    data: assessmentData,
    isLoading: isAssessmentDataLoading,
  } = useAssessment(data?.locationId, { enabled: !!data?.locationId })

  // FIXME: This is a temporary solution to get the user profile refetched
  const {
    data: _userProfileData,
    isLoading: isUserProfileLoading,
  } = useUserProfile()

  if (
    isLoading ||
    !data ||
    isAssessmentDataLoading ||
    !assessmentData ||
    isUserProfileLoading
  ) {
    return <Loader />
  }

  return data.partnerSource ? (
    <PartnerRecommendation />
  ) : (
    <OrganicRecommendation />
  )
}
