import { ImageFromCdn, PrimaryButton, Typography } from '@/components/atoms'
import { ThemeColor } from '@/enums'
import { Button } from '@surein/ui'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Column, Row } from '../../styled'
import {
  AbsoluteImageContainer,
  BigCard,
  CardRow,
  CardTextTitle,
  CardsContainer,
  ClaimManagementCard,
  ClaimManagementCardsContainer,
  ClaimManagementText,
  ClaimManagementTextContainer,
  ClaimManagementTextLine,
  Container,
  IndependentBrokerContainer,
  RadialBg,
  SmallCard,
  StyledIntegrations,
  StyledPhone,
  StyledRiskIndicator,
} from './styles'
import { StyledImageFromCdn } from '@/pages/RecommendationPage/styles'
import { ArrowRight } from 'lucide-react'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    insuranceWallet: t('insuranceWallet'),
    manageAllBusiness: t('manageAllBusiness'),
    claimManagement: t('claimManagement'),
    effortlesslyHandle: t('effortlesslyHandle'),
    courseOfEvents: t('courseOfEvents'),
    explainWhatHappened: t('explainWhatHappened'),
    clientSlipped: t('clientSlipped'),
    riskMonitoring: t('riskMonitoring'),
    stayInformed: t('stayInformed'),
    integrations: t('integrations'),
    ourEcosystem: t('ourEcosystem'),
    next: t('next'),
    independent: t('recommendationIndependentSection.title'),
    independentDescription: t('recommendationIndependentSection.description'),
    independentFootnote: t('recommendationIndependentSection.footnote'),
  }
}

interface AllInOneProps {
  title: string
  subtitle: string
  onButtonClick: () => void
  buttonText: string
}

export const AllInOne = ({
  title,
  subtitle,
  onButtonClick,
  buttonText,
}: AllInOneProps) => {
  const messages = useMessages()
  return (
    <Container>
      <AbsoluteImageContainer>
        <StyledImageFromCdn name="carrier-waterfall" type="png" width="100%" />
      </AbsoluteImageContainer>
      <IndependentBrokerContainer>
        <Typography bold variant="h2">
          {messages.independent}
        </Typography>
        <Typography color={ThemeColor.b50} variant="p1Body">
          {messages.independentDescription}
        </Typography>
        <Typography color={ThemeColor.b40} variant="p2Body">
          {messages.independentFootnote}
        </Typography>
        {onButtonClick && buttonText && (
          <Button
            onClick={onButtonClick}
            variant="primary"
            suffixIcon={ArrowRight}
            shape="square"
          >
            {buttonText}
          </Button>
        )}
      </IndependentBrokerContainer>
      {title && subtitle && (
        <Column alignItems="center" gap="12px">
          <ImageFromCdn
            height={20}
            name="SureinLogo2024"
            type="svg"
            width={84}
          />
          <Typography bold variant="h3">
            {title}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p1Body">
            {subtitle}
          </Typography>
        </Column>
      )}

      <CardsContainer>
        <CardRow>
          <BigCard>
            <CardTextTitle>
              <Typography bold color={ThemeColor.electric900} variant="h3">
                {messages.insuranceWallet}
              </Typography>
              <Typography color={ThemeColor.electric900} variant="p1Body">
                {messages.manageAllBusiness}
              </Typography>
            </CardTextTitle>
            <StyledPhone name="cellphone" type="png" />
            <RadialBg />
          </BigCard>

          <SmallCard>
            <CardTextTitle>
              <Typography bold color={ThemeColor.electric900} variant="h3">
                {messages.claimManagement}
              </Typography>
              <Typography color={ThemeColor.electric900} variant="p1Body">
                {messages.effortlesslyHandle}
              </Typography>
            </CardTextTitle>
            <ClaimManagementCardsContainer>
              <ClaimManagementCard padding="24px">
                <Column gap="4px">
                  <Typography variant="p1Body">
                    {messages.courseOfEvents}
                  </Typography>
                  <Typography color={ThemeColor.b50} variant="p2Body">
                    {messages.explainWhatHappened}
                  </Typography>
                </Column>
                <ClaimManagementTextContainer>
                  <ClaimManagementText
                    color={ThemeColor.electric900}
                    variant="p1Body"
                  >
                    {messages.clientSlipped}
                  </ClaimManagementText>
                  <ClaimManagementTextLine />
                </ClaimManagementTextContainer>
                <PrimaryButton>{messages.next}</PrimaryButton>
              </ClaimManagementCard>
              <ClaimManagementCard />
            </ClaimManagementCardsContainer>
          </SmallCard>
        </CardRow>

        <CardRow>
          <SmallCard>
            <Row alignItems="center">
              <StyledRiskIndicator name="risk-indicator-de" type="webp" />
            </Row>
            <CardTextTitle>
              <Typography bold color={ThemeColor.electric900} variant="h3">
                {messages.riskMonitoring}
              </Typography>
              <Typography color={ThemeColor.electric900} variant="p1Body">
                {messages.stayInformed}
              </Typography>
            </CardTextTitle>
          </SmallCard>

          <BigCard>
            <CardTextTitle>
              <Typography bold color={ThemeColor.electric900} variant="h3">
                {messages.integrations}
              </Typography>
              <Typography color={ThemeColor.electric900} variant="p1Body">
                {messages.ourEcosystem}
              </Typography>
            </CardTextTitle>
            <StyledIntegrations name="integrations" type="svg" />
          </BigCard>
        </CardRow>
      </CardsContainer>
    </Container>
  )
}

AllInOne.propTypes = {
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
