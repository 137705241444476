import { Loader } from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useAddProductToCart,
  useAssessment,
  usePreviewFile,
  useRecommendation,
} from '@/services'
import { useGoToPath } from '@/utils'
import { useParams } from 'react-router-dom'
import { BasePage } from './BasePage'
import { useTranslation } from 'react-i18next'

export const OrganicRecommendation = () => {
  const { recommendationId } = useParams()

  if (!recommendationId) {
    throw new Error('Recommendation ID is required')
  }

  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const previewFile = usePreviewFile()
  const createAddToCartMutation = useAddProductToCart()
  const { data, isLoading } = useRecommendation(recommendationId)
  const {
    data: assessmentData,
    isLoading: isAssessmentDataLoading,
  } = useAssessment(data?.locationId, { enabled: !!data?.locationId })

  const goToCart = useGoToPath(paths.viewCartWithKey(data?.locationId || ''))
  const goToLocation = useGoToPath(
    paths.viewLocationWithKey(data?.locationId || ''),
  )
  const handleBack = () => goToLocation()

  const handlePreview = ({ name }: { name: string }) => {
    previewFile.mutate(name)
  }

  const handleChoose = (id: string) => {
    createAddToCartMutation
      .mutateAsync(id)
      .then(() => {
        snackbar.success(t('snackbars.success.checkoutAdd'))
        goToCart()
      })
      .catch(() => snackbar.error(t('snackbars.error.alreadyInCart')))
  }

  if (isLoading || !data || isAssessmentDataLoading || !assessmentData) {
    return <Loader />
  }

  return (
    <BasePage
      assessmentValues={assessmentData?.values}
      data={data}
      handleFileClick={handlePreview}
      onBack={handleBack}
      onChoose={handleChoose}
    />
  )
}
