import { Loader } from '@/components'
import { config } from '@/config'
import { AssessmentStepKeys } from '@/enums'
import { useAssessment, useSelfAssessment } from '@/services'
import { goToExternalUrl, safeTimestamp } from '@/utils'
import { useOutletContext, useParams } from 'react-router-dom'
import { makeBusinessTypeSubmitValue } from '../helpers'
import { PartnerAssessment } from './BasePage'
import { useMemo } from 'react'
import { omit } from 'ramda'

const findLocationId = (data) => {
  if (data?.companies.length > 0) {
    return data.companies[0].locations[0]
  }
}

const checkPoaSigned = (companies) => {
  const poaSigned = companies[0]?.poaSigned ?? false

  return poaSigned
}

const makeInitialValues = (assessmentData) => {
  const { values } = assessmentData

  return {
    businessType: {
      ...values.businessType,
      locationType:
        values.businessType?.locationType === 'other'
          ? ''
          : values.businessType?.locationType,
    },
    companyFoundedDateLead: {
      foundedDate: safeTimestamp(values.companyFoundedDate?.foundedDate),
    },
    approxRevenue: {
      approxTurnover: values.lastYearFinances?.approxTurnover,
    },
    employeesCount: {
      ...values.employeesCount,
    },
    approxLaborCosts: {
      approxPersonelExpenses: values.lastYearFinances?.approxPersonelExpenses,
    },
    companyDetails: {
      ...values.companyDetails,
    },
    userProfile: {
      ...values.userProfile,
    },
  }
}

const validCategories = ['LI', 'CO', 'LE', 'FL', 'CY']
const validPartners = ['qonto']

const makeSubmitValues = () => (
  {
    approxRevenue,
    approxLaborCosts,
    businessType,
    companyFoundedDateLead,
    ...steps
  },
  poaSigned = false,
) => {
  if (poaSigned) {
    const stepsWithoutDetailsOrProfile = omit(
      ['companyDetails', 'userProfile'],
      steps,
    )

    return {
      ...stepsWithoutDetailsOrProfile,
      lastYearFinances: { ...approxLaborCosts, ...approxRevenue },
      businessType: makeBusinessTypeSubmitValue(businessType),
      companyFoundedDate: companyFoundedDateLead,
      prepareData: {},
    }
  }

  return {
    ...steps,
    lastYearFinances: { ...approxLaborCosts, ...approxRevenue },
    businessType: makeBusinessTypeSubmitValue(businessType),
    companyFoundedDate: companyFoundedDateLead,
    prepareData: {},
  }
}

const makeStepProps = () => (messages) => ({
  [AssessmentStepKeys.approxRevenue]: {
    helperText: messages.helperText,
  },
  [AssessmentStepKeys.approxLaborCosts]: {
    helperText: messages.helperText,
  },
})

const makeSteps = (poaSigned) => {
  return [
    AssessmentStepKeys.businessType,
    AssessmentStepKeys.companyFoundedDateLead,
    AssessmentStepKeys.approxRevenue,
    AssessmentStepKeys.employeesCount,
    AssessmentStepKeys.approxLaborCosts,
    !poaSigned && AssessmentStepKeys.companyDetails,
  ].filter(Boolean)
}

// eslint-disable-next-line import/no-unused-modules
export const PartnerAssessmentPage = () => {
  const { user } = useOutletContext()
  const poaSigned = checkPoaSigned(user.companies)
  const { partnerId, categoryId } = useParams()
  const isValidCategory = validCategories.includes(categoryId)
  const isValidPartner = validPartners.includes(partnerId)

  const steps = useMemo(() => makeSteps(poaSigned), [poaSigned])

  // TODO: these two hooks may use better names
  const {
    data: selfAssessmentData,
    isLoading: isSelfAssessmentLoading,
  } = useSelfAssessment()
  const locationId = findLocationId(selfAssessmentData)
  const {
    data: assessmentData,
    isLoading: isAssessmentLoading,
  } = useAssessment(locationId, {
    enabled: !!locationId,
  })

  if (!isValidPartner || !isValidCategory) {
    goToExternalUrl(config.appUrl)
  }

  if (isSelfAssessmentLoading || isAssessmentLoading) {
    return <Loader />
  }

  return (
    <PartnerAssessment
      initialValues={makeInitialValues(assessmentData)}
      locationId={locationId}
      partnerSource={partnerId}
      categoryId={categoryId}
      poaSigned={poaSigned}
      makeStepProps={makeStepProps()}
      stepKeys={steps}
      transformSubmitValues={makeSubmitValues()}
    />
  )
}
