import {
  ButtonLink,
  ChecklistWidget,
  Column,
  ContactExpert,
  InsuranceCard,
  InsuranceFinderCard,
  Loader,
  OpenMessageComponent,
  PageMainContent,
  PageWidgetContainer,
  PrimaryButton,
  RecommendationCard,
  SecondaryButton,
  ShowWhen,
  TextWithLink,
  Typography,
  YearlyPrice,
} from '@/components'
import { Breakpoint, ComponentColors, ThemeColor } from '@/enums'
import { OptimiseCoverCard } from '@/modules/yearlyCheck/components/OptimiseCoverCard'
import { paths } from '@/routes/paths'
import { useLocationHome, useUserProfile } from '@/services'
import { useFeatureFlag } from '@/services/feature-flags'
import { useTinkIntegrationLink } from '@/services/integrations'
import {
  daysInFuture,
  formatMoney,
  useGoToPath,
  useIsMobile,
  useLocalStorage,
  useTranslationSection,
} from '@/utils'
import { Plus } from 'lucide-react'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import { checkCompletedAssessment } from '../EditLocationPage/helpers'
import {
  insuranceFinderSnoozeLocalStorageKey,
  makeCardData,
  makeExpertContent,
  transform,
} from './helpers'
import {
  AddinsuranceContainer,
  ChecklistWidgetContainer,
  InsuranceCardsContainer,
  InsurancesContainer,
  InsurancesHeader,
  IntegrationsCard,
  MiddleSectionTop,
  PoaCard,
  UpsellCardsContainer,
} from './styles'
import { Button } from '@surein/ui'

const useMessages = () => {
  const pageTranslations = useTranslationSection('locationPage')
  const integrationsTranslations = useTranslationSection('integrationsPage')
  const { t } = useTranslation()

  return {
    addInsurance: pageTranslations('addInsurance'),
    myOffers: pageTranslations('myOffers'),
    contracts: pageTranslations('contracts'),
    recommended: pageTranslations('recommended'),
    offerFromPrice: (lowestPrice) =>
      t('offerFromPrice', {
        lowestPrice: formatMoney(lowestPrice),
      }),
    offerInProgress: t('offerInProgress'),
    startRiskAssessment: t('startRiskAssessment'),
    expertOfferReady: (options) => t('expertOfferReady', options),
    expertOfferReadyOne: (options) => t('expertOfferReadyOne', options),
    expertOptimallyInsured: (options) => t('expertOptimallyInsured', options),
    expertAllEssentialInsurances: (options) =>
      t('expertAllEssentialInsurances', options),
    expertMissingEssentialInsurances: (options) =>
      t('expertMissingEssentialInsurances', options),
    expertOfferInProgress: t('expertOfferInProgress'),
    missingDescription: t('missingDescription'),
    makeClaim: t('makeClaim'),
    signPoa: t('profile.menu.signPOA'),
    poaWarn: t('poaSignPage.prePageSubtitle'),
    poaButton: t('locationPoaButton'),
    connectAccountCTA: integrationsTranslations('connectAccountCTA'),
    connectAccountCTADescription: integrationsTranslations(
      'connectAccountCTADescription',
    ),
    connectAccountCTAButton: integrationsTranslations(
      'connectAccountCTAButton',
    ),
    categories: t('categories', { returnObjects: true }),
  }
}

const PoaBanner = ({ companyId }) => {
  const messages = useMessages()
  const { companies } = useOutletContext()
  const { poaSigned } = find(propEq('companyId', companyId), companies)

  const goToPoa = useGoToPath(`/company/poa/${companyId}`)

  return (
    <ShowWhen when={!poaSigned}>
      <PoaCard>
        <Column gap="8px">
          <Typography variant="captionB">{messages.signPoa}</Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {messages.poaWarn}
          </Typography>
        </Column>
        <PrimaryButton onClick={goToPoa}>{messages.poaButton}</PrimaryButton>
      </PoaCard>
    </ShowWhen>
  )
}

const IntegrationsBanner = () => {
  const messages = useMessages()
  const {
    data: tinkIntegrationLinkData,
    isLoading: isTinkIntegrationLinkDataLoading,
  } = useTinkIntegrationLink()

  const goToIntegrations = useGoToPath(`/profile/integrations`)

  return (
    <ShowWhen
      when={
        !isTinkIntegrationLinkDataLoading &&
        !tinkIntegrationLinkData?.connectionIds
      }
    >
      <IntegrationsCard>
        <Column gap="8px">
          <Typography variant="captionB">
            {messages.connectAccountCTA}
          </Typography>
          <Typography color={ThemeColor.b50} variant="p2Body">
            {messages.connectAccountCTADescription}
          </Typography>
        </Column>
        <PrimaryButton onClick={() => goToIntegrations()}>
          {messages.connectAccountCTAButton}
        </PrimaryButton>
      </IntegrationsCard>
    </ShowWhen>
  )
}

PoaBanner.propTypes = {
  companyId: PropTypes.string.isRequired,
}

export const LocationPage = () => {
  const messages = useMessages()
  const { isMobile, width } = useIsMobile(Breakpoint.sm)
  const { locationId } = useParams()
  const { data, isLoading } = useLocationHome(locationId, {
    select: transform,
  })

  // FIXME: This is a temporary solution to get the user profile refetched
  const {
    data: _userProfileData,
    isLoading: isUserProfileLoading,
  } = useUserProfile()

  const {
    data: riskIndicatorFeatureFlag,
    isLoading: isRiskIndicatorFeatureFlagLoading,
  } = useFeatureFlag('risk-indicator')

  const {
    data: checklistFeatureFlag,
    isLoading: isChecklistFeatureFlagLoading,
  } = useFeatureFlag('risk-checklist')

  const {
    data: yearlyCheckFeatureFlag,
    isLoading: isYearlyCheckFeatureFlagLoading,
  } = useFeatureFlag('yearly-assessment')

  const handleAddInsurance = useGoToPath(paths.addInsuranceContract)

  const goToAssessment = useGoToPath(paths.assessmentWithKey(locationId))

  const [snoozed, setSnoozed] = useLocalStorage(
    insuranceFinderSnoozeLocalStorageKey,
    {},
  )

  const handleSnooze = (categoryId) => {
    setSnoozed({ ...snoozed, [categoryId]: Date.parse(daysInFuture(14)) })
  }

  const upsellCardCategories = useMemo(() => makeCardData(data), [
    JSON.stringify(snoozed),
    data?.insurances,
  ])

  const showYearlyAssessment = data?.yearlyCheckInStatus === 'pending'

  if (
    isLoading ||
    isRiskIndicatorFeatureFlagLoading ||
    isChecklistFeatureFlagLoading ||
    isYearlyCheckFeatureFlagLoading ||
    isUserProfileLoading
  )
    return <Loader />

  return (
    <>
      <PageMainContent>
        {(yearlyCheckFeatureFlag?.state || showYearlyAssessment) && (
          <OptimiseCoverCard locationId={locationId} />
        )}
        <InsurancesContainer>
          <ShowWhen
            when={isMobile && checklistFeatureFlag?.state && data.riskChecklist}
          >
            <ChecklistWidgetContainer isMobile>
              <ChecklistWidget
                companyId={data.companyId}
                data={data.riskChecklist}
                locationId={locationId}
              />
            </ChecklistWidgetContainer>
          </ShowWhen>
          <ShowWhen when={!riskIndicatorFeatureFlag?.state}>
            <MiddleSectionTop>
              <YearlyPrice insurances={data.insurances} />
            </MiddleSectionTop>
          </ShowWhen>
          {Boolean(data?.recommendations?.length) && (
            <>
              <InsurancesHeader>
                <Typography variant="h4">{messages.myOffers}</Typography>
                {isMobile && (
                  <OpenMessageComponent
                    expertButtonComponent={
                      !checkCompletedAssessment(data) && (
                        <SecondaryButton onClick={goToAssessment} size="medium">
                          {messages.startRiskAssessment}
                        </SecondaryButton>
                      )
                    }
                    text={makeExpertContent(data, messages)}
                  />
                )}
              </InsurancesHeader>
              <InsuranceCardsContainer>
                {data.recommendations.map((item) => (
                  <RecommendationCard
                    key={item.recommendationId}
                    {...item}
                    important={data.essentialCategoryId?.includes(
                      item.categoryId,
                    )}
                    primaryDisabled={!item.sended || !item.lowestPrice}
                    primaryText={
                      item.sended && item.lowestPrice
                        ? messages.offerFromPrice(item.lowestPrice)
                        : messages.offerInProgress
                    }
                  />
                ))}
              </InsuranceCardsContainer>
            </>
          )}
          <ShowWhen when={upsellCardCategories.length}>
            <InsurancesHeader>
              <Typography variant="h4">{messages.recommended}</Typography>
            </InsurancesHeader>
            <UpsellCardsContainer>
              {upsellCardCategories.map((item) => (
                <InsuranceFinderCard
                  key={item}
                  categoryId={item}
                  hide={{ features: true }}
                  onSnooze={() => handleSnooze(item)}
                  primaryCta
                />
              ))}
            </UpsellCardsContainer>
          </ShowWhen>
          <InsurancesHeader>
            <AddinsuranceContainer>
              <Typography variant="h4">{messages.contracts}</Typography>
              <Button
                icon={Plus}
                onClick={handleAddInsurance}
                variant="secondary"
                size="sm"
              >
                {messages.addInsurance}
              </Button>
            </AddinsuranceContainer>
          </InsurancesHeader>
          <InsuranceCardsContainer>
            {data.insurances.map((item) => (
              <InsuranceCard
                key={item.insuranceId || item.categoryId}
                {...item}
                show={{
                  details: item.grossPrice || item.policyNumber,
                }}
              />
            ))}
            <TextWithLink>
              <Trans
                components={
                  <ButtonLink
                    color={ComponentColors.primary}
                    onClick={handleAddInsurance}
                    size="small"
                  />
                }
                i18nKey={messages.missingDescription}
              />
            </TextWithLink>
          </InsuranceCardsContainer>
        </InsurancesContainer>
        <ShowWhen when={!checklistFeatureFlag?.state && width < 980}>
          <PoaBanner companyId={data.companyId} />
        </ShowWhen>
      </PageMainContent>
      <PageWidgetContainer>
        <Column gap="24px">
          <ShowWhen when={checklistFeatureFlag?.state && data.riskChecklist}>
            <ChecklistWidgetContainer>
              <ChecklistWidget
                companyId={data.companyId}
                data={data.riskChecklist}
                locationId={locationId}
              />
            </ChecklistWidgetContainer>
          </ShowWhen>
          <ContactExpert
            buttonComponent={
              !checkCompletedAssessment(data) && (
                <SecondaryButton onClick={goToAssessment} size="medium">
                  {messages.startRiskAssessment}
                </SecondaryButton>
              )
            }
            text={makeExpertContent(data, messages)}
          />
          <ShowWhen when={!checklistFeatureFlag?.state}>
            <PoaBanner companyId={data.companyId} />
            <IntegrationsBanner />
          </ShowWhen>
        </Column>
      </PageWidgetContainer>
    </>
  )
}
