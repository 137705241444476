import {
  getSelfAssessment,
  getAssessment,
  putAssessment,
  getKillerQuestions,
  postKillerAnswers,
  getKillerAnswers,
} from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useMutation, useQuery } from 'react-query'

const keys = {
  assessment: 'assessment',
  selfAssessment: 'selfAssessment',
  killerQuestions: 'killerQuestions',
  killerAnswers: 'killerAnswers',
}

export const useSelfAssessment = (options: { enabled: boolean }) =>
  useQuery(keys.selfAssessment, getSelfAssessment, options)

// TODO: fix types
export const useAssessment = (
  locationId?: string,
  options?: { select?: any; enabled?: boolean },
) =>
  useQuery(
    [keys.assessment, locationId],
    locationId ? () => getAssessment(locationId) : () => undefined,
    options,
  )

export const useSaveAssessment = (
  locationId: string,
  isYearlyAssessment: boolean,
) => useMutation((body) => putAssessment(locationId, body, isYearlyAssessment))

export const useKillerQuestions = (
  categoryIds: string[],
  options: { enabled: boolean },
) =>
  useQuery(keys.killerQuestions, () => getKillerQuestions(categoryIds), options)

export const useKillerAnswers = (locationId: string, categoryIds: string[]) =>
  useQuery([keys.killerAnswers, locationId], () =>
    getKillerAnswers({ locationId, categoryIds }),
  )

export const useSaveKillerAnswers = (locationId: string) =>
  useInvalidateOnSuccessMutation([keys.killerAnswers, locationId], (answers) =>
    postKillerAnswers({ locationId, answers }),
  )
