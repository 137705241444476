import PropTypes from 'prop-types'
import { FormInput, FormPhone } from '@/components'
import { AssessmentStepKeys } from '@/enums'
import { useNextLoading, useOnNextClick } from '@/providers'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useAssessmentValues, useSaveAssessmentStep } from '../helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    firstName: t('firstName'),
    lastName: t('surname'),
    phoneNumber: t('phone'),
  }
}

export const UserProfileStep = ({
  isPartnerFunnel = false,
  updateAssessmentMutation,
  isLoading,
  transformSubmitValues,
}) => {
  const messages = useMessages()
  const { values } = useAssessmentValues()
  const { save, isLoading: isSaveLoading } = useSaveAssessmentStep(
    AssessmentStepKeys.userProfile,
  )

  const saveAndSubmitAssessment = useCallback(async () => {
    await save()
    await updateAssessmentMutation.mutateAsync(transformSubmitValues(values))
  }, [values])

  useOnNextClick(isPartnerFunnel ? saveAndSubmitAssessment : save)

  useNextLoading(() => isLoading || isSaveLoading, [isSaveLoading, isLoading])

  useEffect(() => {
    document.getElementsByTagName('input')[0].focus()
  }, [])

  return (
    <>
      <FormInput label={messages.firstName} name="name" />
      <FormInput label={messages.lastName} name="surname" />
      <FormPhone label={messages.phoneNumber} name="phone" />
    </>
  )
}

UserProfileStep.propTypes = {
  isPartnerFunnel: PropTypes.bool,
  updateAssessmentMutation: PropTypes.object,
  isLoading: PropTypes.bool,
  transformSubmitValues: PropTypes.func,
}
