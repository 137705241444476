import { Absolute, AllInOne, Column, Header, Typography } from '@/components'
import { Breadcrumbs } from '@/components/organisms/Stepper/Breadcrumbs'
import { config } from '@/config'
import { ThemeColor } from '@/enums'
import { paths } from '@/routes/paths'
import { theme } from '@/theme'
import { goToExternalUrl, useGoToPath } from '@/utils'
import { Button } from '@surein/ui'
import { ArrowRight, Check } from 'lucide-react'
import Confetti from 'react-confetti'
import { useTranslation } from 'react-i18next'
import {
  ButtonsContainer,
  IconBackground,
  ThankYouPageAnimationContainer,
  ThankYouPageContainer,
  ThankYouTextContainer,
} from './styles'

const useMessages = () => {
  const { t } = useTranslation()
  return {
    checkOutDashboard: t('checkOutDashboard'),
    backToQonto: t('backToQonto'),
    weSentInvite: t('weSentInvite'),
    backToDashboard: t('back_to_dashboard'),
    orderSent: t('orderSent'),
    checkInbox: t('checkInbox'),
    loginNow: t('loginNow'),
  }
}

const BreadcrumbExample = () => {
  const { t } = useTranslation()

  return (
    <Breadcrumbs
      current={1}
      steps={[0, 1, 2]}
      stepperBreadcrumbTitles={[
        t('successPageBreadcrumbs.step1'),
        t('successPageBreadcrumbs.step2'),
        t('successPageBreadcrumbs.step3'),
      ]}
    />
  )
}

export const ThankYouPage = ({ isInPartnerUrl = false }) => {
  const messages = useMessages()
  const goToDashboard = useGoToPath(paths.dashboard)
  const goToApp = () => goToExternalUrl(config.appUrl)
  const goToQonto = () => goToExternalUrl(config.qontoInsuranceHubUrl)

  const handleGoToApp = () => {
    if (isInPartnerUrl) {
      goToApp()
    } else {
      goToDashboard()
    }
  }

  return (
    <Absolute left={0} right={0} top={0}>
      <Header backgroundColor={ThemeColor.b10} simple />
      <ThankYouPageContainer>
        <Column alignItems="center" gap="24px">
          <IconBackground>
            <Check
              color={theme.color.b0}
              strokeWidth={3}
              height="44px"
              width="44px"
            />
          </IconBackground>
          <ThankYouTextContainer>
            <Typography bold variant="h3">
              {messages.orderSent}
            </Typography>
            <Typography color={ThemeColor.b50} variant="p1Body">
              {messages.checkInbox}
            </Typography>
            <BreadcrumbExample />
          </ThankYouTextContainer>
          <ButtonsContainer gap="24px">
            <Button
              onClick={handleGoToApp}
              variant="primary"
              shape="square"
              suffixIcon={ArrowRight}
            >
              {messages.loginNow}
            </Button>
            {isInPartnerUrl && (
              <Button onClick={goToQonto} variant="outline" shape="square">
                {messages.backToQonto}
              </Button>
            )}
          </ButtonsContainer>
        </Column>
        <ThankYouPageAnimationContainer>
          <AllInOne
            buttonText={messages.loginNow}
            onButtonClick={handleGoToApp}
            subtitle={messages.weSentInvite}
            title={messages.checkOutDashboard}
          />
        </ThankYouPageAnimationContainer>
      </ThankYouPageContainer>
      <Confetti recycle={false} />
    </Absolute>
  )
}
