import { updateUserLanguage, getUserProfile, updateUser } from '@/api'
import { useInvalidateOnSuccessMutation } from '@/utils'
import { useQuery } from 'react-query'

const keys = {
  users: 'users',
  user: 'user',
  profile: 'profile',
}

const transform = ({ name, surname, ...rest }) => ({
  fullname: `${name} ${surname}`,
  name,
  surname,
  ...rest,
})

export const useUserProfile = (options) =>
  useQuery(keys.profile, () => getUserProfile(), {
    ...options,
    cacheTime: 0,
    select: transform,
  })

export const useUpdateUser = (id) =>
  useInvalidateOnSuccessMutation([keys], ({ name, surname, phone }) =>
    updateUser(id, { name, surname, phone }),
  )
export const useUpdateUserLanguage = (id) =>
  useInvalidateOnSuccessMutation([keys], ({ language }) =>
    updateUserLanguage(id, { language }),
  )
