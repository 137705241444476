import { Loader } from '@/components'
import { useAuth } from '@/providers'
import { theme } from '@/theme'
import {
  getSessionStorageItem,
  isAdmin,
  isVerified,
  makeUrlWithLanguage,
  safeObject,
  useIsInPath,
  useLanguage,
  useTranslationSectionObject,
} from '@/utils'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import { config } from '../config'
import { paths } from './paths'
import { Redirect } from './Redirect'
import { useLocation, useParams } from 'react-router-dom'

const makeTermsUrl = makeUrlWithLanguage(paths.terms)
const makePolicyUrl = makeUrlWithLanguage(paths.policy)
const makeFirstInformationUrl = makeUrlWithLanguage(paths.initialInformation)

const makeTerms = (message = '', language = '') =>
  `<span>${message
    .replace('/0', '/a')
    .replace(
      '0',
      `a href="${makeTermsUrl(language)}" style="text-decoration: underline"`,
    )
    .replace('/1', '/a')
    .replace(
      '1',
      `a href="${makePolicyUrl(language)}" style="text-decoration: underline"`,
    )
    .replace('/2', '/a')
    .replace(
      '2',
      `a href="${makeFirstInformationUrl(
        language,
      )}" style="text-decoration: underline"`,
    )}</span>`

// eslint-disable-next-line react/display-name
const withVerifiedRequired = (Component) => () => {
  const { user, isLoading } = useAuth()

  const isInNotVerifiedPage = useIsInPath(paths.notVerified)

  if (isLoading) return <Loader />

  if (user) {
    if (!isVerified(user) && !isInNotVerifiedPage) {
      return <Redirect to={paths.notVerified} />
    }

    if (isAdmin(user)) {
      window.location.replace([config.adminApp, paths.authRedirect].join(''))
      return null
    }
  }

  return <Component />
}

export const AuthGuard = (Component) => {
  const language = useLanguage()
  const messages = useTranslationSectionObject('auth0')
  const { state } = useLocation()
  const searchParams = getSessionStorageItem('searchParams')
  const { partnerId } = useParams()

  const toSignup = useIsInPath(paths.signup)

  return withAuthenticationRequired(withVerifiedRequired(Component), {
    onRedirecting: () => <Loader />,
    loginOptions: {
      ui_locales: language,
      glass0: theme.color.b0,
      glass100: theme.color.b10,
      glass300: theme.color.b30,
      glass500: theme.color.b50,
      errorColor: theme.color.danger2,
      ...messages,
      login_hint: state?.login_hint,
      signUpTerms: makeTerms(messages.signUpTerms, language),
      initialScreen: toSignup ? 'signUp' : 'login',
      onlyShowPartner: partnerId,
      ...safeObject(searchParams),
    },
  })
}
