import { i18nHelpers } from '@/i18n/init'
import {
  __,
  anyPass,
  append,
  complement,
  compose,
  concat,
  either,
  equals,
  filter,
  gt,
  head,
  includes,
  insert,
  isEmpty,
  isNil,
  join,
  length,
  map,
  not,
  pipe,
  prepend,
  prop,
  reject,
  split,
  tail,
  toPairs,
  when,
} from 'ramda'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { returns } from './function'
import { config } from '@/config'

const toId = concat(__, 'Id')

export const makeCrudPaths = (path, key) => {
  const documentKey = key || toId(path)
  const root = `/${path}`

  return {
    root,
    view: `${root}/view/:${documentKey}`,
    viewWithKey: concat(`${root}/view/`),
    add: `${root}/add`,
    addWithKey: concat(`${root}/add/`),
    edit: `${root}/edit/:${documentKey}`,
    editWithKey: concat(`${root}/edit/`),
  }
}

export const makeQueryString = compose(
  when(complement(isEmpty), pipe(prepend('?'), join(''))),
  join('&'), // Join each segment of the query with '&'
  map(join('=')), // Join the key-value pairs with '='
  map(map(encodeURIComponent)), // encode keys and values
  toPairs, // convert the object to pairs like `['limit', 5]`
  reject(either(isNil, isEmpty)),
)

export const queryParamsFromString = (search) =>
  search
    ? JSON.parse(
        `{"${search.substring(1).replace(/&/g, '","').replace(/=/g, '":"')}"}`,
        (key, value) => (key === '' ? value : decodeURIComponent(value)),
      )
    : {}

export const makeUrlWithLanguage = (
  pathname,
  baseUrl = window.location.origin,
) =>
  pipe(
    insert(1, __, [baseUrl, tail(pathname)]),
    reject(equals(i18nHelpers.fallbackLng)),
    filter(Boolean),
    join('/'),
  )

export const makePathnameWithLanguage = (language) =>
  pipe(
    tail,
    append(__, ['', language]),
    reject(equals(i18nHelpers.fallbackLng)),
    join('/'),
  )

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer')
}

export const shouldRedirectToDashboard = anyPass([
  pipe(length, gt(__, 1)),
  pipe(head, prop('locations'), length, equals(1), not),
])

export const makeLocationPathFromCompanies = (paths) =>
  pipe(head, prop('locations'), head, paths.viewLocationWithKey)

export const goToExternalUrl = (url) => window.open(url, '_self')

export const useRepeatWithLocale = (...initialPaths) => {
  const { i18n } = useTranslation()
  const { languages } = i18n

  const pathsWithLocale = [...initialPaths]

  initialPaths.forEach((path) => {
    languages.forEach((language) => {
      if (language !== i18nHelpers.fallbackLng)
        pathsWithLocale.push(`/${language}${path}`)
    })
  })

  return pathsWithLocale
}

export const useIsInPath = (...paths) => {
  const { pathname } = useLocation()

  return useRepeatWithLocale(...paths).includes(pathname)
}

export const withoutLanguage = pipe(
  split('/'),
  reject(includes(__, i18nHelpers.languages)),
  join('/'),
  when(isEmpty, returns('/')),
)

export const extractFunnelFromPathname = () => {
  const {
    location: { pathname },
  } = window

  const startIdx = pathname.indexOf('/assessment/') + '/assessment/'.length
  const endIdx = pathname.indexOf('/', startIdx)

  if (startIdx !== -1 && endIdx !== -1) {
    return pathname.substring(startIdx, endIdx)
  }
  return 'unknown'
}

export const checkIsInPartnerUrl = () =>
  window.location.host === config.partnerDomain
