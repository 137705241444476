import { CartCard, Column, Typography } from '@/components'
import { ThemeColor } from '@/enums'
import { useSegment } from '@/modules'
import { useFormContext, useSnackbar } from '@/providers'
import { useLocation, useSubmitCheckout } from '@/services'
import { themeColor } from '@/utils'
import { Button } from '@surein/ui'
import { friendlyFormatIBAN } from 'ibantools'
import { Edit } from 'lucide-react'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useValidateCheckoutStep } from './helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    insurance: t('common.insurance'),
    insurances: t('insurances'),
    totalPrice: t('totalPrice'),
    error: t('snackbars.error.generalMsg'),
    payment: t('checkout.titles.payment'),
    accountName: t('account_name'),
  }
}

const EditButton = styled(Button)`
  padding: 0;
  height: 1rem;
  width: 1rem;
`

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`

const IbanWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${themeColor(ThemeColor.b30)};
  width: 100%;
  margin: 8px 0;
`
export const CheckoutOverviewStep = ({ setButtonState, onNext, goTo }) => {
  const snackbar = useSnackbar()
  const messages = useMessages()
  const { locationId } = useParams()
  const { cart } = useOutletContext()
  const { values } = useFormContext()
  const data = cart[locationId] || []
  const { data: locationData } = useLocation(data[0].locationId)
  const analytics = useSegment()

  const checkoutMutation = useSubmitCheckout()

  const handleSuccess = () => {
    analytics.track('app_checkout_completed')
    onNext()
  }

  const handleSubmit = () => {
    checkoutMutation.mutate(
      {
        paymentId: values.payment.paymentId,
        items: data,
      },
      {
        onSuccess: handleSuccess,
        onError: () => {
          snackbar.error(messages.error)
        },
      },
    )
  }

  const handleNext = useValidateCheckoutStep(handleSubmit)

  useEffect(() => {
    setButtonState({ onClick: handleNext, loading: checkoutMutation.isLoading })

    return () => {
      setButtonState({ loading: false })
    }
  }, [values, checkoutMutation.isLoading])

  return (
    <Column gap="40px">
      <Column gap="24px">
        {values?.payment.iban && (
          <>
            <PaymentMethodWrapper>
              <Typography
                bold
                color={ThemeColor.b50}
                singleLine
                variant="inputDescription"
              >
                Payment method
              </Typography>
              <IbanWrapper>
                <Typography color={ThemeColor.b100} singleLine variant="p2Body">
                  {friendlyFormatIBAN(values?.payment.iban)}
                </Typography>
                <EditButton
                  icon={Edit}
                  onClick={() => goTo('payment')}
                  size="sm"
                  variant="ghost"
                />
              </IbanWrapper>
            </PaymentMethodWrapper>
            <Separator />
          </>
        )}

        {locationData?.name && (
          <Typography
            bold
            color={ThemeColor.b50}
            singleLine
            variant="inputDescription"
          >
            {locationData.name}
          </Typography>
        )}
        {data.map((item) => (
          <CartCard
            key={item.recommendationProductId}
            locationType={data[0].locationType}
            showDelete={false}
            {...item}
          />
        ))}
      </Column>
    </Column>
  )
}

CheckoutOverviewStep.propTypes = {
  goTo: PropTypes.func,
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
