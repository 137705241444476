import { Column, FormCheckBox, FormInputField, Loader, Row } from '@/components'
import PaymentRadio from '@/modules/checkout/components/PaymentRadioButton'
import { useFormContext, useSnackbar } from '@/providers'
import { useCompanyPayments, useCreatePayment } from '@/services'
import { useTranslationSectionObject } from '@/utils'
import { Alert, Button } from '@surein/ui'
import { InfoIcon, Plus } from 'lucide-react'
import PropTypes from 'prop-types'
import { find, propEq } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useValidateCheckoutStep } from './helpers'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    addPayment: t('add_payment_details'),
    noPayments: t('payment_details_missing'),
    add: t('locationPage.addInsurance'),
    save: t('common.save'),
    cancel: t('common.cancel'),
    noneGermanIban: t('profile.payments.noneGermanIban'),
    payments: { ...useTranslationSectionObject('profile.payments') },
    snackBars: { ...useTranslationSectionObject('snackbars') },
    errorAlert: t('paymentMethodError'),
  }
}

const PaymentMethodForm = ({ companyId, setIsAddingPayment }) => {
  const { t } = useTranslation()
  const messages = useMessages()
  const { customValidate, values, setFieldValue } = useFormContext()
  const snackbar = useSnackbar()
  const createPaymentMutation = useCreatePayment()

  const handleSubmit = (e) => {
    e.preventDefault()
    customValidate({
      fields: ['name', 'iban'],
      onSuccess: async () => {
        try {
          const payment = await createPaymentMutation.mutateAsync({
            name: values.name,
            iban: values.iban,
            priority: values.priority ? 1 : 0,
            companyId,
          })

          setFieldValue('name', '')
          setFieldValue('iban', '')
          setFieldValue('priority', 0)

          setFieldValue('payment', payment)

          setIsAddingPayment(false)
        } catch (_error) {
          snackbar.error(messages.snackBars.error.generalMsg)
        }
      },
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <FormInputField label={t('profile.payments.name.label')} name="name" />
        <FormInputField
          label={t('profile.payments.iban.label')}
          name="iban"
          showValidCheck
          validateOnChange
        />
        <FormCheckBox
          id="priority"
          label={messages.payments.isStandard}
          name="priority"
          noMargin
        />
        <Row gap="8px">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setIsAddingPayment(false)}
            loading={createPaymentMutation.isLoading}
          >
            {messages.cancel}
          </Button>
          <Button
            variant="default"
            size="sm"
            onClick={(e) => handleSubmit(e)}
            loading={createPaymentMutation.isLoading}
          >
            {messages.save}
          </Button>
        </Row>
      </div>
    </div>
  )
}

PaymentMethodForm.propTypes = {
  companyId: PropTypes.string,
  setIsAddingPayment: PropTypes.func,
}

export const PaymentMethodStep = ({ companyId, setButtonState, onNext }) => {
  const [isAddingPayment, setIsAddingPayment] = useState(false)
  const { data, isLoading } = useCompanyPayments(companyId)
  const { values, setFieldValue, errors } = useFormContext()
  const messages = useMessages()

  useEffect(() => {
    if (data) {
      if (values?.payment) {
        setFieldValue('payment', values.payment)
        return
      }

      setFieldValue('payment', data.find(propEq('priority', 1)) || data[0])
    }
  }, [data, isAddingPayment])

  const handleNext = useValidateCheckoutStep(onNext, ['payment'])

  const handleSelectPayment = (payment) => {
    setFieldValue('payment', payment)
  }

  const handleAddPayment = () => {
    setIsAddingPayment(true)
    setFieldValue('payment', null)
  }

  useEffect(() => {
    if (!isLoading && data.length) {
      const priorityPayment = find(propEq('priority', 1), data)
      if (priorityPayment && !values?.payment?.paymentId)
        handleSelectPayment(priorityPayment)
    }
  }, [isLoading, data, isAddingPayment])

  useEffect(() => {
    setButtonState({ onClick: handleNext })
  }, [values])

  if (isLoading) return <Loader />

  if (isAddingPayment) {
    return (
      <PaymentMethodForm
        companyId={companyId}
        setIsAddingPayment={setIsAddingPayment}
      />
    )
  }

  return (
    <Column alignItems="start" gap="16px">
      {errors?.payment && (
        <Alert
          icon={InfoIcon}
          iconAlignment="top"
          text={messages.errorAlert}
          variant="danger"
        />
      )}
      {!!data?.length && (
        <PaymentRadio
          onChange={handleSelectPayment}
          paymentMethods={data}
          value={values.payment}
        />
      )}
      <Button icon={Plus} onClick={handleAddPayment} variant="secondary">
        {messages.addPayment}
      </Button>
    </Column>
  )
}

PaymentMethodStep.propTypes = {
  companyId: PropTypes.string,
  onNext: PropTypes.func,
  setButtonState: PropTypes.func,
}
