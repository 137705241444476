import { AssessmentStepKeys, AssessmentType, Categories } from '@/enums'
import { paths } from '@/routes/paths'
import { useRequestRecommendation, useSaveAssessment } from '@/services'
import {
  dynamicObjectPropType,
  propTypeFromEnumKeys,
  useGoToPath,
  useLanguage,
} from '@/utils'
import PropTypes from 'prop-types'
import { identity } from 'ramda'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AssessmentStepper } from '../AssessmentStepper'
import { NoOfferPage } from '../public/NoOfferPage'

const useMessages = () => {
  const { t } = useTranslation()

  return {
    propsMessages: {
      helperText: t('approxHelperText'),
      steps: [
        t('assessmentLoadingStep1'),
        t('liabilityAssessmentLoadingStep2'),
        t('liabilityAssessmentLoadingStep3'),
        t('assessmentLoadingStep5'),
      ],
    },
    subtitle: t('leadStepSubtitle'),
    titles: {
      [AssessmentStepKeys.businessType]: t('businessTypeTitle'),
      [AssessmentStepKeys.companyDetails]: t('companyDetailsTitle'),
      [AssessmentStepKeys.companyFoundedDateLead]: t('companyFoundedDateTitle'),
      [AssessmentStepKeys.employeesCount]: t('employeesCountTitle'),
      [AssessmentStepKeys.approxRevenue]: t('approxRevenueTitle'),
      [AssessmentStepKeys.inventory]: t('inventoryTitle'),
      [AssessmentStepKeys.approxLaborCosts]: t('approxLaborCostsTitle'),
      [AssessmentStepKeys.prepareData]: t('preparingOffer'),
      [AssessmentStepKeys.prepareOfferB]: t('preparingOffer'),
      [AssessmentStepKeys.contactData]: t('createLeadTitle'),
      [AssessmentStepKeys.createAccount]: t('createAccountTitle'),
      [AssessmentStepKeys.createAccountB]: t('createAccountTitle'),
      [AssessmentStepKeys.startDate]: t('startDateTitle'),
      [AssessmentStepKeys.userProfile]: t('userProfileStepTitle'),
    },
  }
}

const makeAdditionalSteps = (poaSigned) => {
  return [
    AssessmentStepKeys.prepareOfferB,
    !poaSigned && AssessmentStepKeys.userProfile,
  ].filter(Boolean)
}

// eslint-disable-next-line import/no-unused-modules
export const PartnerAssessment = ({
  onLogoClick,
  categoryId,
  locationId,
  partnerSource,
  poaSigned,
  stepKeys,
  makeStepProps,
  stepperBreadcrumbTitles,
  initialValues,
  transformSubmitValues = identity,
}) => {
  const [errorState, setErrorState] = useState(false)
  const language = useLanguage()
  const messages = useMessages()
  const requestOfferMutation = useRequestRecommendation(locationId)
  const updateAssessmentMutation = useSaveAssessment(locationId)
  const goToRecommendation = useGoToPath(paths.viewRecommendationWithKey)
  const additionalSteps = useMemo(() => makeAdditionalSteps(poaSigned), [
    poaSigned,
  ])

  const stepProps = useMemo(
    () => ({
      ...makeStepProps(messages.propsMessages),
      [AssessmentStepKeys.prepareData]: {
        stepsMessages: messages.propsMessages.steps,
        hideButtons: true,
      },
      [AssessmentStepKeys.prepareOfferB]: {
        hideButtons: true,
        categoryId,
        poaSigned,
        updateAssessmentMutation: updateAssessmentMutation,
        transformSubmitValues,
        isPartnerFunnel: true,
      },
      [AssessmentStepKeys.userProfile]: {
        isPartnerFunnel: true,
        updateAssessmentMutation: updateAssessmentMutation,
        isLoading:
          updateAssessmentMutation.isLoading || requestOfferMutation.isLoading,
        transformSubmitValues,
      },
    }),
    [language, categoryId, updateAssessmentMutation, requestOfferMutation],
  )

  const handleFinish = async () => {
    requestOfferMutation
      .mutateAsync({ categoryId, partnerSource })
      .then((data) => {
        if (data?.recommendationId) {
          goToRecommendation(data.recommendationId)
        } else {
          setErrorState(true)
        }
      })
  }

  if (errorState) {
    return <NoOfferPage />
  }

  return (
    <AssessmentStepper
      initialValues={initialValues}
      messages={messages}
      onFinish={handleFinish}
      onLogoClick={onLogoClick}
      skipSuccessPage
      stepKeys={[...stepKeys, ...additionalSteps]}
      stepperBreadcrumbTitles={stepperBreadcrumbTitles}
      stepProps={stepProps}
      type={AssessmentType.firstTimeUser}
    />
  )
}

PartnerAssessment.propTypes = {
  initialValues: PropTypes.object,
  categoryId: propTypeFromEnumKeys(Categories),
  locationId: PropTypes.string,
  partnerSource: PropTypes.string.isRequired,
  makeStepProps: PropTypes.func.isRequired,
  onLogoClick: PropTypes.func,
  stepKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepperBreadcrumbTitles: dynamicObjectPropType,
  transformSubmitValues: PropTypes.func,
  poaSigned: PropTypes.bool,
}
