import { Loader } from '@/components'
import { useSnackbar } from '@/providers'
import { paths } from '@/routes/paths'
import {
  useAddProductToCart,
  useAssessment,
  useClearCart,
  usePreviewFile,
  useRecommendation,
} from '@/services'
import { useGoToPath } from '@/utils'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BasePage } from './BasePage'
import { makeBoughtProductAnalyticsPayload, useSegment } from '@/modules'

// eslint-disable-next-line import/no-unused-modules
export const PartnerRecommendation = () => {
  const { recommendationId } = useParams()

  if (!recommendationId) {
    throw new Error('Recommendation ID is required')
  }

  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const previewFile = usePreviewFile()
  const createAddToCartMutation = useAddProductToCart()
  const clearCart = useClearCart()
  const { data, isLoading } = useRecommendation(recommendationId)
  const goToCheckout = useGoToPath(
    paths.viewCheckoutWithKey(data?.locationId || ''),
  )
  const analytics = useSegment()
  const {
    data: assessmentData,
    isLoading: isAssessmentDataLoading,
  } = useAssessment(data?.locationId, { enabled: !!data?.locationId })

  const handlePreview = ({ name }: { name: string }) => {
    previewFile.mutate(name)
  }

  const handleChoose = async (id: string) => {
    try {
      // @ts-expect-error fix type - requires TS migration of cart service
      await clearCart.mutateAsync()
      await createAddToCartMutation.mutateAsync(id)
      await analytics.track('partner_recommendation_bought', {
        ...makeBoughtProductAnalyticsPayload(id, data),
      })
      goToCheckout()
    } catch (_error) {
      snackbar.error(t('snackbars.error.generalMsg'))
    }
  }

  if (isLoading || !data || isAssessmentDataLoading || !assessmentData) {
    return <Loader />
  }

  return (
    <BasePage
      assessmentValues={assessmentData?.values}
      data={data}
      handleFileClick={handlePreview}
      noBack
      onChoose={handleChoose}
    />
  )
}
